import React from "react";
import { NavLink as Link } from "react-router-dom";


// Assets
import "./OurServices.css";
import GroupTherapy from "../../images/services-group-therapy-img.jpg";
import AddictionMgt from "../../images/services-addiction-img.jpg";
import IndividualTheraphy from "../../images/services-individual-img.jpg";
import EAPService from "../../images/services-eap-img.jpg";
import InPatientCare from "../../images/in-patient-care.jpg";
import Assessment from "../../images/services-assessment-img.jpg";


const OurServices = () => {

  // Click event that scroll to the top of the page
  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };  

  return (
    <div className="Services">
        <h1 className="service-title">Our <span className="highlight">Services</span></h1>
        <div className="main-services">

            <div className="service">
                <div className="image-div">
                    <img src={GroupTherapy} alt="Group-Therapy" />
                    <Link to="/our-approach" onClick={handleClickScroll}>
                        <div className="overlay">
                            <div className="cta-text">Learn More</div>
                        </div>
                    </Link>
                </div>
                <h2 className="service-text">
                    Group <span className="highlight">Therapy</span>
                </h2>
            </div>

            <div className="service">
                <div className="image-div">
                    <img src={InPatientCare} alt="Group-Therapy" />
                    <Link to="/in-patient-care-program" onClick={handleClickScroll}>
                        <div className="overlay">
                            <div className="cta-text">Learn More</div>
                        </div>
                    </Link>
                </div>
                <h2 className="service-text">
                <span className="highlight">In-patient </span>Care
                </h2>
            </div>

            <div className="service">
                <div className="image-div">
                    <img src={AddictionMgt} alt="Group-Therapy" />
                    <Link to="/our-approach" onClick={handleClickScroll}>
                        <div className="overlay">
                            <div className="cta-text">Learn More</div>
                        </div>
                    </Link>
                </div>
                <h2 className="service-text">
                Addiction <span className="highlight">Management</span>
                </h2>
            </div>

            <div className="service">
                <div className="image-div">
                    <img src={IndividualTheraphy} alt="Group-Therapy" />
                    <Link to="/our-approach" onClick={handleClickScroll}>
                        <div className="overlay">
                            <div className="cta-text">Learn More</div>
                        </div>
                    </Link>
                </div>
                <h2 className="service-text">
                Individual <span className="highlight">Therapy</span>
                </h2>
            </div>

            <div className="service">
                <div className="image-div">
                    <img src={EAPService} alt="Group-Therapy" />
                    <Link to="/our-approach" onClick={handleClickScroll}>
                        <div className="overlay">
                            <div className="cta-text">Learn More</div>
                        </div>
                    </Link>
                </div>
                <h2 className="service-text">
                EAP <span className="highlight">Services</span>
                </h2>
            </div>

            <div className="service">
                <div className="image-div">
                    <img src={Assessment} alt="Group-Therapy" />
                    <Link to="/our-approach" onClick={handleClickScroll}>
                        <div className="overlay">
                            <div className="cta-text">Learn More</div>
                        </div>
                    </Link>
                </div>
                <h2 className="service-text">
                    <span className="highlight">Assessments</span>
                </h2>
            </div>
        </div>
    </div>
  )
}

export default OurServices