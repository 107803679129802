import React from "react";
import "./Maps.css";

const Map = () => {
  return (
    <div className="Maps">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.6815340355997!2d3.4671265792793577!3d6.434935876941421!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf5b248b5a91d%3A0xd7de2293390e4279!2s20%20Rahman%20Adeboyejo%20St%2C%20Lekki%20Phase%20I%2C%20Lekki%20106104%2C%20Lagos!5e0!3m2!1sen!2sng!4v1731582479195!5m2!1sen!2sng"
        width="646"
        height="310"
        style={{ 
          border: 1,
          borderRadius: 30, 
          marginTop: 40
        }}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        className="Maps-frame"
      ></iframe>
    </div>
  );
};

export default Map;
