import React, { useState } from "react";
import { PopupButton } from "react-calendly";
import "./PricingTable.css";
import { consultantsRates, psychologistsRates, otherRates } from "../data/Data"
// import { NavLink as Link } from "react-router-dom";
import "./PricingTable.css";
// import policyDocument from './the-unforsaken-our-policy.pdf';
import policyDocs from "./The-Unforsaken-Our-Policy.pdf";

const PricingTable = () => {
//   Click event that scroll to the top of the page
//   const handleClickScroll = () => {
//     window.scrollTo(0, 0);
//   };

    // State to track the selected currency
    const [currency, setCurrency] = useState("naira");

    // Function to handle currency change
    const handleCurrencyChange = (currency) => {
        setCurrency(currency);
    };

  return (
    <div className="PricingTable wrapper" id="prices">
        
        <div className="toggle-nheader">
            <p className="table-currency">Currency</p>

            <div className="toggle-button-cover">
                <div className="button r" id="button-3">
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={currency === "pounds"}
                        onChange={() => handleCurrencyChange(currency === "naira" ? "pounds" : "naira")}
                    />
                    <div className={`naira ${currency === "naira" ? "active" : ""}`} onClick={() => handleCurrencyChange("naira")}></div>
                    <div className={`pounds ${currency === "pounds" ? "active" : ""}`} onClick={() => handleCurrencyChange("pounds")}></div>
                </div>
            </div>
        </div>

        <p className="table-caption">Consultants’ Pricing Rate</p>
        <div className="table-wrapper">
            <table className="pricing">
                <tr className="table-head">
                    <th>SN</th>
                    <th>Service Name</th>
                    <th>Price</th>
                    {/* <th>Note</th> */}
                </tr>

                {/* table body */}
                {consultantsRates.map((consultPrice, i) => (
                    <tr key={i} className="price-item">
                        <td className="sn">{consultPrice.sn}</td>
                        <td className="service-name">{consultPrice.serviceName}</td>
                        <td className="price">{currency === "naira" ? consultPrice.priceNaira : consultPrice.pricePound}</td>
                    </tr>
                ))}
            </table>
        </div>


        <p className="table-caption">Psycologist’s Pricing Rate</p>
        <div className="table-wrapper">
            <table className="pricing">
                <tr className="table-head">
                    <th>SN</th>
                    <th>Service Name</th>
                    <th>Price</th>
                    {/* <th>Note</th> */}
                </tr>

                {/* table body */}
                {psychologistsRates.map((psycPrice, i) => (
                    <tr key={i} className="price-item">
                        <td className="sn">{psycPrice.sn}</td>
                        <td className="service-name">{psycPrice.serviceName}</td>
                        <td className="price">{currency === "naira" ? psycPrice.priceNaira : psycPrice.pricePound}</td>
                    </tr>
                ))}
            </table>
        </div>


        <p className="table-caption">Other Rates</p>
        <div className="table-wrapper">
            <table className="pricing">
                <tr className="table-head">
                    <th>SN</th>
                    <th>Service Name</th>
                    <th>Price</th>
                    {/* <th>Note</th> */}
                </tr>

                {/* table body */}
                {otherRates.map((ordPrice, i) => (
                    <tr key={i} className="price-item">
                        <td className="sn">{ordPrice.sn}</td>
                        <td className="service-name">
                            {ordPrice.serviceName}<br />
                            <span className="note">{ordPrice.note}</span>
                        </td>
                        <td className="price">{currency === "naira" ? ordPrice.priceNaira : ordPrice.pricePound}</td>
                    </tr>
                ))}
            </table>
        </div>
        <p className="policy-doc">Click <a href={policyDocs} download="The Unforsaken-Our-Policy.pdf">here</a> to view policy document</p>

        <PopupButton
            url="https://calendly.com/theunforsaken"
            rootElement={document.getElementById("root")}
            text="Book a Session"
            className="btn btn-primary"
            styles={{whiteSpace: "nowrap", marginBottom: "80px", }}
        />
        
    </div>
  )
}

export default PricingTable;