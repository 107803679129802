import React, { useState, useEffect } from "react";
import { NavLink as Link, useResolvedPath, useMatch } from "react-router-dom";
// https://calendly.com/theunforsaken

// Assets
import "./Header.css";
import Logo from "../../images/main-logo.png";
import MobileLogo from "../../images/logo-white.png";
import DropDown from "../../images/drop-down.svg";

const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [clicked, setClicked] = useState(true);

  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };

  const handleClick = () => {
    setClicked(!clicked);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`${scrollPosition > 0 ? "with-shadow" : ""}`}>
      <input type="checkbox" name="show" id="show-mobile-menu" />

      {/* Logo */}
      <div className="header-logo">
        <NavLink to="/">
          <img src={Logo} alt="Menu-Logo" onClick={handleClickScroll} className="logo" />
        </NavLink>
        <NavLink to="/">
          <img src={MobileLogo} alt="Menu-Logo" onClick={handleClickScroll} className="m-logo" />
        </NavLink>
      </div>

      {/* Menu Links */}
      <ul className="menu-links">
        <NavLink to="/" className="Link" onClick={handleClickScroll}>
          Home
        </NavLink>
        <NavLink to="/conditions" className="Link drop-down" onClick={handleClickScroll}>
          Conditions
          <img src={DropDown} alt="drop-down" />
          <ul className="dropdown-menu">
            <NavLink to="/conditions/adhd" className="dropdown-item">ADHD</NavLink>
            <NavLink to="/conditions/personality-disorder" className="dropdown-item">A Personality Disorder</NavLink>
            <NavLink to="/conditions/anger-issues" className="dropdown-item">Anger Issues</NavLink>
            <NavLink to="/conditions/autism" className="dropdown-item">Autism</NavLink>
            <NavLink to="/conditions/behaviour-addictions" className="dropdown-item">Behaviour Addictions</NavLink>
            <NavLink to="/conditions/behaviour-school-problems" className="dropdown-item">Behaviour and School Problems</NavLink>
            <NavLink to="/conditions/bipolar-disorder" className="dropdown-item">Bipolar Disorder</NavLink>
            <NavLink to="/conditions/body-dysmorphic-disorder" className="dropdown-item">Body Dysmorphic Disorder</NavLink>
            <NavLink to="/conditions/borderline-personality-disorder" className="dropdown-item">Borderline Personality Disorder</NavLink>
            <NavLink to="/conditions/depression" className="dropdown-item">Depression</NavLink>
            <NavLink to="/conditions/dissociative-disorders" className="dropdown-item">Dissociative Disorders</NavLink>
            <NavLink to="/conditions/drugs-alcohol-addiction" className="dropdown-item">Drugs and Alcohol Addiction</NavLink>
            <NavLink to="/conditions/eating-disorders" className="dropdown-item">Eating Disorders</NavLink>
            <NavLink to="/conditions/marital-problems" className="dropdown-item">Marital Problems</NavLink>
            <NavLink to="/conditions/Narcissistic-personality-disorder" className="dropdown-item">Narcissistic Personality Disorder</NavLink>
          </ul>
        </NavLink>
        <NavLink to="/about-us" className="Link" onClick={handleClickScroll}>
          About Us
        </NavLink>
        <NavLink to="/our-approach" className="Link" onClick={handleClickScroll}>
          Our Approach
        </NavLink>
        <NavLink to="/blog" className="Link">
          Blog
        </NavLink>
        <NavLink to="/contact-us" className="Link" onClick={handleClickScroll}>
          Contact Us
        </NavLink>

        <li className="Mobile-Buttons">
          <Link to="/book-session"  onClick={handleClickScroll}><div className="btn btnHL">Book a Session</div></Link>
        </li>
      </ul>

      {/* Buttons */}
      <li className="Buttons">
        <Link to="/book-session" onClick={handleClickScroll}><div className="btn btnHL">Book a Session</div></Link>
      </li>

      {/* Mobile Screens */}
      <div className="menu">
        <label htmlFor="show-mobile-menu">
          <i
            id="bar"
            className={clicked ? "fa-solid fa-bars" : "fas fa-times"}
            onClick={handleClick}
          ></i>
        </label>
      </div>
    </header>
  );
};

function NavLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}

export default Header;
